import { getQueryParam } from "helpers/searchParam"
import { useLang } from "hooks/useLang"
import React, { useState, useRef, useEffect } from "react"

type Option = {
    label: string
    value: string
}

type CustomSelectProps = {
    options: Option[]
    placeholder?: string
    onSelect: (value: string) => void
}

const CustomSelect: React.FC<CustomSelectProps> = ({
    options,
    placeholder = "Select an option",
    onSelect,
}) => {
    const selectedLocation = getQueryParam("location")

    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState<string | null>(
        selectedLocation ?? null
    )
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const toggleDropdown = () => setIsOpen((prev) => !prev)

    const handleOptionClick = (value: string) => {
        setSelected(value)
        setIsOpen(false)
        onSelect(value)
    }

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const { t } = useLang()

    return (
        <div className="relative w-40" ref={dropdownRef}>
            {/* Select Header */}

            <div
                className="flex justify-between items-center cursor-pointer"
                onClick={toggleDropdown}
            >
                <span className="underline bg-transparent text-[16px] text-orange-500 ml-1 appearance-none">
                    {selected
                        ? t(options.find((o) => o.value === selected)?.label!)
                        : t(placeholder!)}
                </span>
            </div>

            {/* Options List */}
            {isOpen && (
                <ul className="absolute mt-2 w-full backdrop-blur border dark:border-[#fff] border-[#a6aab3] rounded-lg shadow-xl z-10 overflow-hidden">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className={`px-4 py-2 dark:hover:bg-[#fff] dark:hover:text-[#000]  overflow-hidden  hover:bg-[#eaeaea]  hover:backdrop-blur cursor-pointer text-[#000] dark:text-[#fff] ${
                                selected === option.value
                                    ? "dark:bg-[#fff] dark:text-[#000]   bg-[#eaeaea]"
                                    : ""
                            }`}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {t(option.label)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default CustomSelect
