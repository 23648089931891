import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react"
import { useEffect } from "react"
import { FeatureFlags, FeatureFlagValue } from "types/FeatureFlags"

interface IProps {
    children: React.ReactNode
    flag_key: FeatureFlagValue
}

const FeatureFlagWrapper = ({ children, flag_key }: IProps) => {
    const isFlagEnabled = useFeatureFlagEnabled(flag_key)
    const payload = useFeatureFlagPayload(flag_key)

    useEffect(() => {
        // if the payload values change, rerender the children component
        //TODO: implement the logic for handling when values change.
        return () => {
            // cleanup function
        }
    }, [isFlagEnabled, payload])

    return <>{isFlagEnabled && payload === true ? children : null}</>
}

export default FeatureFlagWrapper
