import { useLang } from "hooks/useLang"
import { useRouter } from "next/router"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

import CustomImage from "./atoms/Image/CustomImage"
import { Advertisement } from "types/Advertisements"

interface IProps {
    advertisement: Advertisement
}

const AdsComponent = ({ advertisement }: IProps) => {
    const { multiLangVars, isAr } = useLang()
    const { push } = useRouter()

    const onClick = () => {
        push(
            advertisement.type === "single"
                ? `/events/${advertisement.events[0]}`
                : `/events/advertisement/${advertisement._id}`
        )
    }

    const imageVal = advertisement.web_image ?? advertisement.image

    return (
        <div
            className={`relative h-40 w-full items-center justify-center overflow-hidden rounded-2xl md:h-60 xl:h-80`}>
            <CustomImage
                src={process.env.NEXT_PUBLIC_Event_Img_URL + imageVal}
                alt={
                    advertisement?.[
                        multiLangVars("title") as "en_title" | "ar_title"
                    ]
                }
                style={{ objectFit: "cover" }}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
            <div className="relative flex h-full w-full items-end justify-between bg-gradient-to-t from-black p-2 md:p-5">
                <p className="w-full text-base font-semibold text-white sm:max-w-[50%] sm:text-xl md:text-2xl">
                    {
                        advertisement?.[
                            multiLangVars("title") as "en_title" | "ar_title"
                        ]
                    }
                </p>
                {advertisement.type === "none" ? null : isAr ? (
                    <FiChevronLeft
                        size={20}
                        onClick={onClick}
                        className="hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 text-black backdrop-blur-md transition-all dark:border-transparent md:flex md:h-10 md:w-10 md:hover:bg-opacity-70 md:dark:hover:bg-opacity-70"
                    />
                ) : (
                    <FiChevronRight
                        size={20}
                        onClick={onClick}
                        className="hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 text-black backdrop-blur-md transition-all dark:border-transparent md:flex md:h-10 md:w-10 md:hover:bg-opacity-70 md:dark:hover:bg-opacity-70"
                    />
                )}
            </div>
        </div>
    )
}
export default AdsComponent
