export default function FilterComponentSkeleton() {
    return (
        <div className="relative w-full animate-pulse rounded-lg border-[1px] border-white border-opacity-60 px-8 py-4 text-white">
            <div className="flex flex-wrap items-center justify-between gap-y-5">
                <div className="h-4 w-1/4 rounded bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                <div className="flex gap-4">
                    <div className="h-8 w-16 rounded bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                    <div className="h-8 w-16 rounded bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                    <div className="h-8 w-16 rounded bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                    <div className="h-8 w-16 rounded bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                </div>
            </div>
        </div>
    )
}