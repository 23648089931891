import { useLang } from "hooks/useLang"
import { useRouter } from "next/router"
import { IDistinations } from "types/distinations"
import CustomImage from "./atoms/Image/CustomImage"

interface IProps {
    distination: IDistinations
}

const CityCard = ({ distination }: IProps) => {
    const { multiLangVars } = useLang()
    const { push } = useRouter()

    return (
        <div
            onClick={() =>
                push(`/events/city/${distination.en_title.toLowerCase()}`)
            }
            className="group relative flex h-[150px] w-full cursor-pointer items-center justify-center  overflow-hidden rounded-3xl hover:shadow-md md:h-[200px]">
            <CustomImage
                src={process.env.NEXT_PUBLIC_Event_Img_URL + distination.icon}
                alt={
                    distination?.[
                        multiLangVars("title") as "en_title" | "ar_title"
                    ]
                }
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                style={{ objectFit: "cover" }}
                className="transition-all duration-700 md:group-hover:scale-110"
            />
            <span className="relative flex h-full w-full items-end bg-[#1d154410] bg-gradient-to-t from-[#1D1544CC] p-5 text-center text-base font-bold text-white transition-all hover:bg-[#1d154420] md:text-2xl md:group-hover:bg-opacity-30">
                {
                    distination?.[
                        multiLangVars("title") as "en_title" | "ar_title"
                    ]
                }
            </span>
        </div>
    )
}

export default CityCard
