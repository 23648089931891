import axios from "axios"

const getDistinations = async () => {
    const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}distinations`
    )
    return data
}

export default getDistinations
