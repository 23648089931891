import Image from "next/image"
import React from "react"
import { useLang } from "hooks/useLang"
import Link from "next/link"

const AppBanner = () => {
    const { t } = useLang()

    return (
        <section className="relative mt-28 flex h-[360px] w-full bg-secondary-theme-400 md:h-[460px]">
            <div className="layout mx-auto flex w-full items-center justify-between gap-5">
                <div className="flex flex-1 flex-col">
                    <div className="flex flex-col gap-2 text-xl font-bold text-white md:text-4xl">
                        <h3>{t("app_section_haeder")}</h3>
                    </div>
                    <p className="w-full max-w-[600px] py-8 text-sm text-white md:text-base">
                        {t("app_section_desc")}
                    </p>
                    <div className="flex items-center gap-2">
                        <Link
                            target="_blank"
                            className="flex cursor-pointer items-center justify-center"
                            href={
                                "https://apps.apple.com/us/app/pass-tickets/id1596912352"
                            }>
                            <Image
                                src={"/svg/v2/app_store.png"}
                                alt="app-store"
                                width={120}
                                height={50}
                            />
                        </Link>
                        <Link
                            target="_blank"
                            className="flex cursor-pointer items-center justify-center"
                            href={
                                "https://play.google.com/store/apps/details?id=com.starthub.passtickets"
                            }>
                            <Image
                                src={"/svg/v2/google_play.png"}
                                alt="google-store"
                                width={120}
                                height={50}
                            />
                        </Link>
                    </div>
                </div>
                <div className="items flex flex-1 justify-center md:pb-8 xl:pb-16 z-10">
                    <Image
                        src="/images/smartphone-v4.webp"
                        alt="app-section-temp-Image"
                        height={400}
                        width={400}
                        className="pointer-events-none drop-shadow-2xl"
                    />
                </div>
            </div>
        </section>
    )
}

export default AppBanner
