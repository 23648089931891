import { IFinalEvent } from "types/event";

const SPECIAL_EVENTS = ["672cdbeca294b9a80a037b17",'66eaa9f10c3c0a69b8ab51c9'];

export const sortEventsByStart = (events: IFinalEvent[]) => {
    const specialEvents = events?.filter((event) => {
        return SPECIAL_EVENTS.includes(event?._id);
    }) || [];


    const otherEvents = events?.filter((event) => {
        return !SPECIAL_EVENTS.includes(event?._id);
    }).sort((a, b) => {
        if (a.createdAt > b.createdAt) {
            return -1;
        }
        if (a.createdAt < b.createdAt) {
            return 1;
        }
        return 0;
    }) || [];

    // Combine special and other events, ensuring no duplicates
    const combinedEvents = [...specialEvents, ...otherEvents];
    return combinedEvents.filter((event, index, self) => {
        return self.findIndex((e) => e?._id === event?._id) === index;
    });
};
