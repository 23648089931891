import React, { useState, useEffect } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import Skeleton from "./Skeleton/Skeleton"
import { useLang } from "hooks/useLang"
import CustomImage from "./atoms/Image/CustomImage"
import { Advertisement } from "types/Advertisements"
import useDevice from "hooks/useDevice"
import Link from "next/link"

interface IProps {
    advertisements: Advertisement[]
    isLoading?: boolean
}

const HeroVTwo = ({ advertisements, isLoading = false }: IProps) => {
    const { isAr } = useLang()
    const { osType } = useDevice()
    const [isMounted, setIsMounted] = useState(false)
    const isIosOrMac = osType === "ios" || osType === "mac"

    useEffect(() => {
        setIsMounted(true)
    }, [])

    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            initial: 0,
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel)
            },
            renderMode: "performance",
            created() {
                setLoaded(true)
            },
            rtl: isAr,
            loop: advertisements?.length > 1 ? true : false,
        },
        [
            (slider) => {
                let timeout
                let mouseOver = false
                function clearNextTimeout() {
                    clearTimeout(timeout)
                }
                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider?.next()
                    }, 4000)
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })
                slider.on("dragStarted", clearNextTimeout)
                slider.on("animationEnded", nextTimeout)
                slider.on("updated", nextTimeout)
            },
        ]
    )

    const staticImages = [
        "https://passticketscdn.azureedge.net/frontend/hero.png",
        "https://passticketscdn.azureedge.net/frontend/hero2.png",
        "https://passticketscdn.azureedge.net/frontend/Frame 1000003662.png",
    ]

    const handleDotClick = (index: number) => {
        if (instanceRef.current) {
            instanceRef.current.moveToIdx(index)
        }
    }

    // advertisements?.sort((a, b) => {
    //     if (a?.createdAt > b?.createdAt) {
    //         return -1
    //     } else {
    //         return 1
    //     }
    // })

    if (isLoading || !isMounted) {
        return (
            <Skeleton
                count={1}
                type="hero_image"
                className={`${
                    isIosOrMac ? "h-64 md:h-96 lg:h-screen" : "aspect-video"
                } max-h-screen w-full overflow-hidden md:block`}
            />
        )
    }

    if (advertisements?.length === 0) {
        return (
            <>
                <div className="navigation-wrapper relative">
                    <>
                        <div ref={sliderRef} className="keen-slider">
                            {staticImages.map((image, index) => (
                                <div
                                    key={index}
                                    className={`keen-slider__slide relative ${
                                        isIosOrMac
                                            ? "h-64 md:h-96 lg:h-screen"
                                            : "aspect-video"
                                    } max-h-screen w-full overflow-hidden md:block number-slide${
                                        index + 1
                                    }`}
                                >
                                    <CustomImage
                                        src={image}
                                        alt={"image"}
                                        style={{
                                            objectFit: "cover",
                                        }}
                                        fill
                                        priority
                                    />
                                </div>
                            ))}
                        </div>
                        <>
                            {loaded && instanceRef.current && (
                                <>
                                    <div className="pointer-events-none absolute bottom-0 left-1/2 flex h-full w-full -translate-x-1/2 items-end justify-center gap-2 bg-gradient-to-t from-white from-0% to-30% pb-2 dark:from-primary-theme-500"></div>
                                    <div className="dots absolute bottom-0 left-0 flex h-5 w-full items-center justify-center gap-2 md:h-10">
                                        {[
                                            ...Array(
                                                instanceRef.current.track
                                                    .details?.slides.length
                                            ).keys(),
                                        ].map((idx) => {
                                            return (
                                                <span
                                                    key={idx}
                                                    onClick={() =>
                                                        handleDotClick(idx)
                                                    }
                                                    className={`dot h-2 cursor-pointer rounded-full transition-all md:h-3 ${
                                                        currentSlide === idx
                                                            ? "active w-7 bg-primary-theme-200 dark:bg-action-theme-200 md:w-10"
                                                            : "w-2 bg-gray-400 dark:bg-gray-600 md:w-3"
                                                    }`}
                                                />
                                            )
                                        })}
                                    </div>
                                </>
                            )}
                        </>
                    </>
                </div>
            </>
        )
    }

    return (
        <div className="navigation-wrapper relative">
            <div
                ref={sliderRef}
                className={`${
                    isIosOrMac ? "h-64 md:h-96 lg:h-screen" : "aspect-video"
                } keen-slider max-h-screen w-full overflow-hidden md:block`}
            >
                {advertisements.map(
                    (advertisement: Advertisement, i: number) => {
                        const href =
                            advertisement.type === "single"
                                ? `/events/${advertisement.events[0]}`
                                : `/events/advertisement/${advertisement._id}`

                        return (
                            <Link
                                key={advertisement._id}
                                href={href}
                                className={`keen-slider__slide relative w-full cursor-pointer overflow-hidden md:block number-slide${
                                    i + 1
                                }`}
                            >
                                <CustomImage
                                    src={
                                        process.env.NEXT_PUBLIC_Event_Img_URL +
                                        (advertisement.web_image ||
                                            advertisement.image)
                                    }
                                    alt={
                                        advertisement.ar_title ??
                                        advertisement.en_title
                                    }
                                    style={{
                                        objectFit: "cover",
                                    }}
                                    fill
                                    priority
                                />
                            </Link>
                        )
                    }
                )}
            </div>

            <>
                {loaded && instanceRef.current && advertisements.length > 1 && (
                    <>
                        <div className="pointer-events-none absolute bottom-0 left-1/2 flex h-full w-full -translate-x-1/2 items-end justify-center gap-2 bg-gradient-to-t from-white from-0% to-30% pb-2 dark:from-primary-theme-500"></div>
                        <div className="dots absolute bottom-0 left-0 flex h-5 w-full items-center justify-center gap-2 md:h-10">
                            {[
                                ...Array(
                                    instanceRef.current.track.details?.slides
                                        .length
                                ).keys(),
                            ].map((idx) => {
                                return (
                                    <span
                                        key={idx}
                                        onClick={() => handleDotClick(idx)}
                                        className={`dot h-2 cursor-pointer rounded-full transition-all md:h-3 ${
                                            currentSlide === idx
                                                ? "active w-7 bg-primary-theme-200 dark:bg-action-theme-200 md:w-10"
                                                : "w-2 bg-gray-400 dark:bg-gray-600 md:w-3"
                                        }`}
                                    />
                                )
                            })}
                        </div>
                    </>
                )}
            </>
            {advertisements.length === 1 && (
                <>
                    <div className="pointer-events-none absolute bottom-0 left-1/2 flex h-full w-full -translate-x-1/2 items-end justify-center gap-2 bg-gradient-to-t from-white from-0% to-30% pb-2 dark:from-primary-theme-500"></div>
                    <div className="dots absolute bottom-0 left-0 flex h-5 w-full items-center justify-center gap-2 md:h-10"></div>
                </>
            )}
        </div>
    )
}

export default HeroVTwo
