import "keen-slider/keen-slider.min.css"
import React from "react"
import AppBanner from "components/Sections/AppsSection/AppBanner"
import HeroVTwo from "components/HeroVTwo"
import { MainLayout } from "components/Layout"
import AdSection from "components/Sections/AdSection/AdSection"
import CitySection from "components/Sections/CitySection/CitySection"
import DiscoverSection from "components/Sections/DiscoverSection/DiscoverSection"
import EventsSections from "components/Sections/EventsSections/EventsSections"
import { AdvertisementResponse } from "types/Advertisements"
import { NextSeo } from "next-seo"
import { ArSEO } from "../seo/index"

export interface IPromo {
    eventTitle: string
    status: boolean
    id: string
}

interface PageProps {
    ads: AdvertisementResponse["payload"]
}

export async function getServerSideProps() {
    try {
        const res = await fetch(
            `${process.env.NEXT_PUBLIC_API_URL}advertisement-groups`
        )

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`)
        }

        const contentType = res.headers.get("content-type")
        if (!contentType || !contentType.includes("application/json")) {
            throw new Error("Oops, we haven't got JSON!")
        }

        const data = (await res.json()) as AdvertisementResponse
        const ads = data.payload

        return {
            props: { ads },
        }
    } catch (error) {
        console.error("Error fetching advertisement data:", error)
        return {
            props: { ads: [] },
        }
    }
}
const NewHomePage = (props: PageProps) => {
    // const { data, isLoading: isAdvertisementLoading } =
    //     useQuery<AdvertisementResponse>({
    //         queryKey: ["advertisement"],
    //         queryFn: () => getAdvertisement(),
    //         initialData: props.payload,
    //     })
    const advertisement = props.ads

    const advertisementSliders = advertisement?.filter(
        (ad) => ad.web_view_type === "slider"
    )
    const advertisementGrid = advertisement?.filter(
        (ad) => ad.web_view_type === "grid"
    )

    return (
        <>
            <NextSeo {...ArSEO} />

            {/* <Survey /> */}

            <MainLayout>
                <div className="flex flex-col">
                    <HeroVTwo advertisements={advertisementSliders!} />
                    <DiscoverSection isLoading={false} />

                    <div>
                        <EventsSections maxEventsToShow={12} />

                        {/* <FeatureFlagWrapper flag="showcategories"> */}
                        {/* <CategorySection /> */}
                        {/* </FeatureFlagWrapper> */}

                        {/* <SingleAdSection /> */}

                        {advertisement?.length === 0 ? null : (
                            <AdSection
                                advertisements={advertisementGrid!}
                                isLoading={false}
                            />
                        )}
                        <CitySection />
                    </div>

                    <AppBanner />

                    {/* <SearchByKeyword /> */}
                </div>
            </MainLayout>
        </>
    )
}

export default NewHomePage
