import React, { useEffect } from "react"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import { useLang } from "hooks/useLang"
import { useRouter } from "next/router"
import { twMerge } from "tailwind-merge"
import useWindowSize from "hooks/useWindowSize"
import { useKeenSlider } from "keen-slider/react"

interface ISection {
    title?: string
    description?: string
    children: React.ReactNode
    type?: "default" | "slider"
    slug?: string
    id?: string
    key?: any
    showArrows?: boolean
    showSeeMoreButton?: boolean
    className?: string
    sectionHeaderClassName?: string
    sectionSliderClassName?: string
    sliderView?: { sm: number; md: number; lg: number; xl: number }
    sliderOrigin?: number | "auto" | "center" | undefined
    sliderStartingIndex?: number
}

const Section = ({
    title,
    description,
    children,
    type = "default",
    sliderView,
    id,
    key,
    showArrows = false,
    showSeeMoreButton = false,
    sliderOrigin = "auto",
    className: customClassName,
    sectionHeaderClassName,
    sectionSliderClassName,
    sliderStartingIndex,
}: ISection) => {
    const { push } = useRouter()
    const { isAr, t } = useLang()
    const { isMobile } = useWindowSize()

    const [sliderRef, instanceRef] = useKeenSlider({
        initial: sliderStartingIndex ?? 0,
        rtl: isAr,
        breakpoints: {
            "(min-width: 500px)": {
                slides: {
                    perView: sliderView ? sliderView.md : 2.5,
                    spacing: 15,
                    origin: sliderOrigin,
                },
            },
            "(min-width: 900px)": {
                slides: {
                    perView: sliderView ? sliderView.lg : 3.5,
                    spacing: 15,
                    origin: sliderOrigin,
                },
            },
            "(min-width: 1300px)": {
                slides: {
                    perView: sliderView ? sliderView.xl : 4.5,
                    spacing: 15,
                    origin: sliderOrigin,
                },
            },
        },
        slides: {
            perView: sliderView ? sliderView.sm : 1,
            spacing: 10,
            origin: sliderOrigin,
        },
    })

    useEffect(() => {
        if (instanceRef.current) {
            instanceRef.current.update()
        }
    }, [children])

    return (
        <section
            id={id}
            key={key}
            className={twMerge(
                "my-16 flex flex-col gap-5 md:my-28 md:gap-8",
                customClassName
            )}>
            <div
                className={twMerge(
                    "flex items-end justify-between",
                    sectionHeaderClassName
                )}>
                <div className="flex flex-col gap-0">
                    <h2 className="text-xl font-bold text-black dark:text-white md:text-2xl">
                        {title}
                    </h2>
                    <p className="hidden max-w-[220px] text-sm text-gray-700 dark:text-gray-100 sm:max-w-[400px] md:block">
                        {description}
                    </p>
                </div>
                <div className="flex flex-row-reverse gap-x-5">
                    {showArrows && (
                        <div className="flex items-center gap-2 md:gap-4">
                            <FiChevronLeft
                                className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 transition-all dark:border-transparent dark:bg-primary-theme-100 md:h-10 md:w-10 md:hover:bg-gray-200 md:dark:hover:bg-opacity-70 ${isAr ? "rotate-180" : "rotate-0"}`}
                                onClick={() => instanceRef.current?.prev()}
                            />
                            <FiChevronRight
                                className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 transition-all dark:border-transparent dark:bg-primary-theme-100 md:h-10 md:w-10 md:hover:bg-gray-200 md:dark:hover:bg-opacity-70 ${isAr ? "rotate-180" : "rotate-0"}`}
                                onClick={() => instanceRef?.current?.next()}
                            />
                        </div>
                    )}
                    {showSeeMoreButton && isMobile && (
                        <div
                            onClick={() => push(`/events/list/${id}`)}
                            role="link"
                            className="mx-auto w-fit cursor-pointer rounded-md border border-transparent bg-gray-100 px-4 py-2 text-center text-xs transition-all dark:border-transparent dark:bg-primary-theme-100 md:px-6 md:text-sm md:hover:border-gray-300 md:dark:hover:bg-opacity-70">
                            {t("see_more")}
                        </div>
                    )}
                </div>
            </div>

            {type === "slider" ? (
                <div
                    ref={sliderRef}
                    className={`keen-slider ${sectionSliderClassName}`}>
                    {children}
                </div>
            ) : (
                children
            )}

            {showSeeMoreButton && !isMobile && (
                <div>
                    <div
                        onClick={() => push(`/events/list/${id}`)}
                        role="link"
                        className="mx-auto w-fit cursor-pointer rounded-md border border-transparent bg-gray-100 px-4 py-2 text-center text-xs transition-all dark:border-transparent dark:bg-primary-theme-100 md:px-6 md:text-sm md:hover:border-gray-300 md:dark:hover:bg-opacity-70">
                        {t("see_more")}
                    </div>
                </div>
            )}
        </section>
    )
}

export default Section
