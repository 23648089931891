import React from "react"
import Section from "../Section"
import { useQuery } from "@tanstack/react-query"
import { useLang } from "hooks/useLang"
import CityCard from "components/CityCard"
import Skeleton from "components/Skeleton/Skeleton"
import getDistinations from "api/sections/getDistinations"

const CitySection = () => {
    const { t, isAr } = useLang()

    const { data, isLoading } = useQuery({
        queryKey: ["distinations"],
        queryFn: getDistinations,
    })

    if (isLoading) {
        return (
            <Skeleton
                type="small_card"
                title
                className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-16 md:mb-28 md:h-[200px] h-[150px]"
            />
        )
    }

    return (
        <Section
            type="slider"
            sectionHeaderClassName="layout xl:!px-5"
            sliderOrigin={0.07}
            showSeeMoreButton={false}
            showArrows={true}
            sliderView={{
                sm: 1.5,
                md: 2.5,
                lg: 3.5,
                xl: 4.5,
            }}
            id={"select-city"}
            title={t("distinations_title")}>
            {data?.payload.map((distination: any, index: number) => (
                <div
                    className="keen-slider__slide"
                    key={index}>
                    <CityCard distination={distination} />
                </div>
            ))}
        </Section>
    )
}

export default CitySection
