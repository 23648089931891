import dayjs from "dayjs"
import { IFinalEvent, TimeSlot } from "types/event"
import IEvent from "types/IEvent"
import isoWeek from "dayjs/plugin/isoWeek"

dayjs.extend(isoWeek)

export enum FilterOption {
    All = "all",
    Today = "today",
    Week = "week",
    Month = "month",
}

export const filterEventsByDate = (
    filter: FilterOption,
    start_date: Date,
    end_date: Date,
    time_slots: Array<TimeSlot>
): boolean => {
    const eventStartDate = new Date(start_date)
    const eventEndDate = new Date(end_date)
    const today = new Date()

    var isTimeSlotAvailableToday: any = false

    switch (filter) {
        case FilterOption.All:
            return true
        case FilterOption.Today: {
            if (today >= eventStartDate && today <= eventEndDate) {
                time_slots.find((ts) => {
                    const timeSlotStartingDate = new Date(ts.start_slot)
                    const timeSlotEndinggDate = new Date(ts.end_slot)
                    if (
                        today >= timeSlotStartingDate &&
                        today <= timeSlotEndinggDate
                    ) {
                        isTimeSlotAvailableToday = true
                    }
                })
            }

            return isTimeSlotAvailableToday
            // return today >= eventStartDate && today <= eventEndDate
        }
        case FilterOption.Week: {
            time_slots.find((ts) => {
                const timeSlotStartingDate = new Date(ts.start_slot)
                if (dayjs().isSame(timeSlotStartingDate, "week")) {
                    isTimeSlotAvailableToday = true
                }
            })

            return isTimeSlotAvailableToday
        }

        case FilterOption.Month: {
            if (
                eventStartDate.getMonth() === today.getMonth() &&
                eventStartDate.getFullYear() === today.getFullYear()
            ) {
                time_slots.find((ts) => {
                    const timeSlotStartingDate = new Date(ts.start_slot)
                    // const timeSlotEndinggDate = new Date(ts.end_slot)
                    if (
                        today.getMonth() === timeSlotStartingDate.getMonth() &&
                        timeSlotStartingDate.getFullYear() ===
                            today.getFullYear()
                    ) {
                        isTimeSlotAvailableToday = true
                    }
                })
            }

            return isTimeSlotAvailableToday
        }
        default:
            return true
    }
}
