export function addQueryParam(key, value) {
    var currentUrl = ""
    if (typeof window !== "undefined") {
        // Get the current URL
        currentUrl = window.location.href
    }

    // Create a URL object from the current URL
    const url = new URL(currentUrl)

    // Use URLSearchParams to modify the query string
    const params = new URLSearchParams(url.search)

    // Set the new key-value pair
    params.set(key, value)

    // Update the URL with the new query string
    url.search = params.toString()

    // Use history.replaceState to update the URL without reloading the page
    if (typeof window !== "undefined") {
        // Get the current URL
        window.history.replaceState({}, "", url)
    }
}

export function getQueryParam(key) {
    // Get the current URL
    var currentUrl = ""
    if (typeof window !== "undefined") {
        // Get the current URL
        currentUrl = window.location.href
    }

    // Create a URL object from the current URL
    const url = new URL(currentUrl)

    // Use URLSearchParams to get the value of the specified key
    const params = new URLSearchParams(url.search)

    // Return the value of the key or null if it doesn't exist
    return params.get(key)
}

export function searchQueryParam(key: string) {
    // Get the current URL
    var currentUrl = ""
    if (typeof window !== "undefined") {
        // Get the current URL
        currentUrl = window.location.href
    }

    // Create a URL object from the current URL
    const url = new URL(currentUrl)

    // Use URLSearchParams to get the value of the specified key
    const params = new URLSearchParams(url.search)

    // Return the value of the key or null if it doesn't exist
    return params.has(key)
}

export function removeParameterFromUrl(keys: Array<string>) {
    var currentUrl = ""
    if (typeof window !== "undefined") {
        // Get the current URL
        currentUrl = window.location.href
    }
    let url = new URL(currentUrl)
    let params = new URLSearchParams(url.search)

    // Delete the foo parameter.

    keys.map((k) => params.delete(k))
    //Query string is now: 'bar=2'

    // now join the query param and host
    let newUrl = url.origin + "/" + params.toString()
    if (typeof window !== "undefined") {
        // Get the current URL
        window.history.replaceState({}, "", newUrl)
    }
}
