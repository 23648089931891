import DiscoverCard from "components/DiscoverCard"
import { useKeenSlider } from "keen-slider/react"
import { useLang } from "hooks/useLang"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import Skeleton from "components/Skeleton/Skeleton"
interface IProps {
    isLoading: boolean
}

// make sure there is no duplicate titles
const DISCOVER_DATA = [
    {
        title: "family",
        image: "/svg/v2/discover_family.png",
    },
    {
        title: "kids",
        image: "/svg/v2/discover_kids.jpg",
    },
    {
        title: "individual",
        image: "/svg/v2/discover_individual.jpg",
    },
    {
        title: "woman",
        image: "/svg/v2/discover_woman.webp",
    },
    {
        title: "couples",
        image: "/svg/v2/discover_couples.jpg",
    },
]

const DiscoverSection = ({ isLoading }: IProps) => {
    const { isAr, t } = useLang()

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        rtl: isAr,
        breakpoints: {
            "(min-width: 500px)": {
                slides: {
                    perView: 2,
                    spacing: 15,
                },
            },
            "(min-width: 900px)": {
                slides: {
                    perView: 2.5,
                    spacing: 20,
                },
            },
            "(min-width: 1680px)": {
                slides: {
                    perView: 3.5,
                    spacing: 20,
                },
            },
            "(min-width: 2000px)": {
                slides: {
                    perView: 4.5,
                    spacing: 20,
                },
            },
        },
        slides: {
            perView: 1.2,
            spacing: 10,
        },
    })

    if (isLoading) {
        return (
            <Skeleton
                type="small_card"
                className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-10 px-20 lg:h-[315px] h-[165px]"
            />
        )
    }

    return (
        <section
            className={`mt-10 flex w-full max-w-[2400px] md:gap-5 ${
                isAr
                    ? "pr-[1.2rem] lg:pr-[7.7rem]"
                    : "pl-[1.2rem] lg:pl-[7.7rem]"
            }`}>
            <div
                className={`flex w-full max-w-[150px] flex-col justify-between md:max-w-[300px]`}>
                <h1 className="text-xl font-bold capitalize text-black dark:text-white md:text-2xl">
                    {t("discover_upcoming_events")}
                </h1>
                <span className="text-xs md:text-sm text-gray-700 dark:text-gray-100">
                    {t("discover_sectino_desc")}
                </span>

                <div className="flex items-center gap-2 md:gap-4">
                    <FiChevronLeft
                        className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 transition-all dark:border-transparent dark:bg-primary-theme-100 md:h-10 md:w-10 md:hover:bg-gray-200 md:dark:hover:bg-opacity-70 ${
                            isAr ? "rotate-180" : "rotate-0"
                        }`}
                        onClick={() => instanceRef.current?.prev()}
                    />
                    <FiChevronRight
                        className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 transition-all dark:border-transparent dark:bg-primary-theme-100 md:h-10 md:w-10 md:hover:bg-gray-200 md:dark:hover:bg-opacity-70 ${
                            isAr ? "rotate-180" : "rotate-0"
                        }`}
                        onClick={() => instanceRef?.current?.next()}
                    />
                </div>
            </div>
            <div
                ref={sliderRef}
                className="keen-slider">
                {DISCOVER_DATA.map((item) => (
                    <div
                        // make sure there is no duplicate titles
                        key={item.title}
                        className={`keen-slider__slide ${
                            isAr ? "last:pl-5" : "last:pr-5"
                        }`}>
                        <DiscoverCard
                            image={item.image}
                            title={item.title}
                        />
                    </div>
                ))}
            </div>
        </section>
    )
}

export default DiscoverSection
