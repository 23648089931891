import { useEffect, useState } from "react"

type DeviceType = "iphone" | "android" | "ipad" | "windows" | "mac" | "unknown"
type BrowserType =
    | "safari"
    | "chrome"
    | "firefox"
    | "edge"
    | "opera"
    | "unknown"
type OsType = "ios" | "android" | "windows" | "mac" | "unknown"

interface DeviceInfo {
    deviceType: DeviceType
    browserType: BrowserType
    osType: OsType
}

const useDevice = (): DeviceInfo => {
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
        deviceType: "unknown",
        browserType: "unknown",
        osType: "unknown",
    })

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()

        let deviceType: DeviceType = "unknown"
        if (/iphone/.test(userAgent)) {
            deviceType = "iphone"
        } else if (/ipad/.test(userAgent)) {
            deviceType = "ipad"
        } else if (/android/.test(userAgent)) {
            deviceType = "android"
        } else if (/windows/.test(userAgent)) {
            deviceType = "windows"
        } else if (/mac/.test(userAgent) && !/iphone|ipad/.test(userAgent)) {
            deviceType = "mac"
        }

        let osType: OsType = "unknown"
        if (/iphone|ipad/.test(userAgent)) {
            osType = "ios"
        } else if (/android/.test(userAgent)) {
            osType = "android"
        } else if (/windows/.test(userAgent)) {
            osType = "windows"
        } else if (/mac/.test(userAgent) && !/iphone|ipad/.test(userAgent)) {
            osType = "mac"
        }

        let browserType: BrowserType = "unknown"
        if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
            browserType = "safari"
        } else if (/chrome/.test(userAgent) && !/edge/.test(userAgent)) {
            browserType = "chrome"
        } else if (/firefox/.test(userAgent)) {
            browserType = "firefox"
        } else if (/edg/.test(userAgent)) {
            browserType = "edge"
        } else if (/opera|opr/.test(userAgent)) {
            browserType = "opera"
        }

        setDeviceInfo({ deviceType, browserType, osType })
    }, [])

    return deviceInfo
}

export default useDevice
