import Section from "../Section"
import AdsComponent from "components/AdsComponent"
import Skeleton from "components/Skeleton/Skeleton"
import { Advertisement } from "types/Advertisements"

interface IProps {
    advertisements: Advertisement[]
    isLoading: boolean
}
const AdSection = ({ advertisements, isLoading }: IProps) => {
    if (isLoading) {
        return (
            <Skeleton
                count={2}
                type="small_card"
                title
                className="grid grid-cols-1 gap-5 lg:grid-cols-2 mb-16 md:mb-28 h-[400px] md:h-[200px]"
            />
        )
    }

    if (advertisements?.length === 0) {
        return null
    }

    return (
        <Section>
            <div className="grid grid-cols-2 gap-2 md:gap-5">
                {advertisements?.map(
                    (advertisement: Advertisement, index: number) => (
                        <AdsComponent
                            advertisement={advertisement}
                            key={index}
                        />
                    )
                )}
            </div>
        </Section>
    )
}

export default AdSection
